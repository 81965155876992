import { useEffect } from 'react';
import { Box, Divider, useMediaQuery } from '@mui/material';
import Header from '../components/header';
import { useTheme } from '@mui/material/styles';
import ContactForm from '../components/contact-form'
import { useTranslation } from 'react-i18next';
import useLanguage from '../useLanguage';


const ContactPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { i18n } = useTranslation();

    const { language }= useLanguage();

    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);

    return (
        <div
            style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
            }}
        >
            <Header textColor="#000000" backgroundColor="white"/>
            <Box sx={{ flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                mt: 12,
                ml: isMobile? 3 : 8,
                pt: '64px'
            }}>
                <h1 style={{ fontSize: isMobile? '2rem' : '4rem', marginBottom: '0.5rem' }}>Contact.</h1>
                {i18n.language === 'ja' && (
                    <h3 style={{ marginTop: '0' }}>お問い合わせ</h3>
                )}
                {isMobile && 
                    <Divider sx={{ mt: 3, mb: 3, mr: 2 }} />
                }
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <ContactForm isMobile={isMobile}/>
                </Box>
            </Box>
        </div>

    )
}

export default ContactPage;