import React, { useEffect } from 'react';
import { Box, Divider, useMediaQuery, Grid, Button } from '@mui/material';
import Header from '../components/header';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useLanguage from '../useLanguage';
import { useNavigate } from 'react-router-dom';

const OrderMatePage = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { language }= useLanguage();
    const navigate = useNavigate();

    const formatText = (text: string) => {
        return text.split('\n').map((str, index) => (
            <React.Fragment key={index}>
                {str}
                <br />
            </React.Fragment>
        ));
    };

    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);


    return (
        <div
            style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
            }}
        >
            <Header textColor="#000000" backgroundColor="white"/>
            <Box sx={{ flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                mt: 12,
                ml: isMobile? 3 : 8,
                pt: '64px'
            }}>
                <h1 style={{ fontSize: isMobile? '2rem' : '4rem', marginBottom: '0.5rem' }}>OrderMate.</h1>
                {i18n.language === 'ja' && (
                    <h3 style={{ marginTop: '0' }}>オーダーメイト</h3>
                )}
                {isMobile && 
                    <Divider sx={{ mt: 3, mb: 3, mr: 2 }} />
                }
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item md={5}>
                        <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                            <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                {t('Order Mate Title')}
                            </span>
                        </Box>
                        <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ 
                                    fontSize: '1rem', 
                                    padding: '28px 80px', 
                                    backgroundColor: '#45B6AF', 
                                    '&:hover': {
                                        backgroundColor: '#45B6AF',
                                    },
                                    '&:focus': {
                                        backgroundColor: '#45B6AF',
                                    }
                                }}
                                onClick={() => navigate('/contact')}
                            >
                                {t('Consult First')}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        <Box sx={{ mt: isMobile ? 0 : 3, position: 'relative' }}>
                            <span style={{ fontSize: '1.2rem' }}>
                                {formatText(t('Order Mate Description'))}
                            </span>
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 10, mb: 3, mr: isMobile? 2 : 10 }} />
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item md={11}>
                        <Box sx={{ mt: isMobile ? 0 : 3, position: 'relative' }}>
                            <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                                {formatText(t('Order Mate Header'))}
                            </span>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item md={3}>
                            <Box sx={{ mt: 10 }}>
                                <span style={{ fontSize: '1.5rem' }}>
                                    {t('Order Mate Seven Features')}
                                </span>
                            </Box>
                        </Grid>
                        <Grid item md={8}>
                            <Box sx={{ mt: 10 }}>
                                <Divider sx={{ mt: 3, mb: 3 }} />
                                <Grid container spacing={4} alignItems="center">
                                    <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box sx={{ width: 100, height: 100, borderRadius: '50%', border: '2px solid #45B6AF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>01</span>
                                        </Box>
                                    </Grid>
                                    <Grid item md={10}>
                                        <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                            {t('Order Mate Feature1.title')}
                                        </span>
                                        <p style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
                                            {t('Order Mate Feature1.description')}
                                        </p>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ mt: 5 }}>
                                <Divider sx={{ mt: 3, mb: 3 }} />
                                <Grid container spacing={4} alignItems="center">
                                    <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box sx={{ width: 100, height: 100, borderRadius: '50%', border: '2px solid #45B6AF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>02</span>
                                        </Box>
                                    </Grid>
                                    <Grid item md={10}>
                                        <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                            {t('Order Mate Feature2.title')}
                                        </span>
                                        <p style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
                                            {t('Order Mate Feature2.description')}
                                        </p>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ mt: 5 }}>
                                <Divider sx={{ mt: 3, mb: 3 }} />
                                <Grid container spacing={4} alignItems="center">
                                    <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box sx={{ width: 100, height: 100, borderRadius: '50%', border: '2px solid #45B6AF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>03</span>
                                        </Box>
                                    </Grid>
                                    <Grid item md={10}>
                                        <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                            {t('Order Mate Feature3.title')}
                                        </span>
                                        <p style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
                                            {t('Order Mate Feature3.description')}
                                        </p>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ mt: 5 }}>
                                <Divider sx={{ mt: 3, mb: 3 }} />
                                <Grid container spacing={4} alignItems="center">
                                    <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box sx={{ width: 100, height: 100, borderRadius: '50%', border: '2px solid #45B6AF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>04</span>
                                        </Box>
                                    </Grid>
                                    <Grid item md={10}>
                                        <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                            {t('Order Mate Feature4.title')}
                                        </span>
                                        <p style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
                                            {t('Order Mate Feature4.description')}
                                        </p>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ mt: 5 }}>
                                <Divider sx={{ mt: 3, mb: 3 }} />
                                <Grid container spacing={4} alignItems="center">
                                    <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box sx={{ width: 100, height: 100, borderRadius: '50%', border: '2px solid #45B6AF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>05</span>
                                        </Box>
                                    </Grid>
                                    <Grid item md={10}>
                                        <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                            {t('Order Mate Feature5.title')}
                                        </span>
                                        <p style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
                                            {t('Order Mate Feature5.description')}
                                        </p>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ mt: 5 }}>
                                <Divider sx={{ mt: 3, mb: 3 }} />
                                <Grid container spacing={4} alignItems="center">
                                    <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box sx={{ width: 100, height: 100, borderRadius: '50%', border: '2px solid #45B6AF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>06</span>
                                        </Box>
                                    </Grid>
                                    <Grid item md={10}>
                                        <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                            {t('Order Mate Feature6.title')}
                                        </span>
                                        <p style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
                                            {t('Order Mate Feature6.description')}
                                        </p>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ mt: 5 }}>
                                <Divider sx={{ mt: 3, mb: 3 }} />
                                <Grid container spacing={4} alignItems="center">
                                    <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box sx={{ width: 100, height: 100, borderRadius: '50%', border: '2px solid #45B6AF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>07</span>
                                        </Box>
                                    </Grid>
                                    <Grid item md={10}>
                                        <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                            {t('Order Mate Feature7.title')}
                                        </span>
                                        <p style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
                                            {t('Order Mate Feature7.description')}
                                        </p>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ mt: 5 }}>
                                <Divider sx={{ mt: 3, mb: 3 }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}/>
            </Box>
        </div>

    )
}

export default OrderMatePage;