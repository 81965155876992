import React, { useEffect } from 'react';
import { Box, Divider, useMediaQuery } from '@mui/material';
import Header from '../components/header';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useLanguage from '../useLanguage';

const PolicyPage = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { language }= useLanguage();

    const formatText = (text: string) => {
        return text.split('\n').map((str, index) => (
            <React.Fragment key={index}>
                {str}
                <br />
            </React.Fragment>
        ));
    };

    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);

    return (
        <div
            style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
            }}
        >
            <Header textColor="#000000" backgroundColor="white"/>
            <Box sx={{ flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                mt: 12,
                ml: isMobile? 3 : 8,
                pt: '64px'
            }}>
                <h1 style={{ fontSize: isMobile? '2rem' : '4rem', marginBottom: '0.5rem' }}>Policy.</h1>
                {i18n.language === 'ja' && (
                    <h3 style={{ marginTop: '0' }}>ポリシー</h3>
                )}
                {isMobile && 
                    <Divider sx={{ mt: 3, mb: 3, mr: 2 }} />
                }
                <Box sx={{ mt: isMobile? 1 : 6 }}>
                    <h1 style={{ fontSize: '1.5rem' }}>{t('Privacy policy')}</h1>
                </Box>
                <Box sx={{ display: 'flex', mt: 8 }}>
                    <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                        {t('section1_title')}
                    </span>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <span style={{ fontSize: '1.1rem' }}>
                        {formatText(t('section1_content'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', mt: 6 }}>
                    <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                        {t('section2_title')}
                    </span>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <span style={{ fontSize: '1.1rem' }}>
                        {formatText(t('section2_content'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', mt: 8 }}>
                    <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                        {t('section3_title')}
                    </span>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <span style={{ fontSize: '1.1rem' }}>
                        {formatText(t('section3_content'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', mt: 8 }}>
                    <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                        {t('section4_title')}
                    </span>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <span style={{ fontSize: '1.1rem' }}>
                        {formatText(t('section4_content'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', mt: 8 }}>
                    <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                        {t('section5_title')}
                    </span>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <span style={{ fontSize: '1.1rem' }}>
                        {formatText(t('section5_content'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', mt: 8 }}>
                    <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                        {t('section6_title')}
                    </span>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <span style={{ fontSize: '1.1rem' }}>
                        {formatText(t('section6_content'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', mt: 8 }}>
                    <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                        {t('section7_title')}
                    </span>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <span style={{ fontSize: '1.1rem' }}>
                        {formatText(t('section7_content'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', mt: 8 }}>
                    <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                        {t('section8_title')}
                    </span>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <span style={{ fontSize: '1.1rem' }}>
                        {formatText(t('section8_content'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', mt: 8 }}>
                    <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                        {t('section9_title')}
                    </span>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <span style={{ fontSize: '1.1rem' }}>
                        {formatText(t('section9_content'))}
                    </span>
                </Box>
                <Box sx={{ mt: 10 }}></Box>
            </Box>
        </div>

    )
}

export default PolicyPage;