import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Checkbox, FormControlLabel, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ContactFormProps {
    isMobile?: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({ isMobile = false }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        company_name: '',
        email: '',
        tell_no: '',
        message: '',
        is_policy: false
    });

    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        tell_no: '',
        message: '',
        is_policy: ''
    });

    const handleChange = (e: any) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone: string) => {
        const phoneRegex = /^\d{2,4}-\d{2,4}-\d{4}$/;
        return phoneRegex.test(phone);
    };

    const validateForm = () => {
        const newErrors: any = {};
        if (!formData.first_name) newErrors.first_name = t('errors.first_name_required');
        if (!formData.last_name) newErrors.last_name = t('errors.last_name_required');
        if (!formData.email) newErrors.email = t('errors.email_required');
        else if (!validateEmail(formData.email)) newErrors.email = t('errors.email_invalid');
        if (formData.tell_no && !validatePhone(formData.tell_no)) newErrors.tell_no = t('errors.phone_invalid');
        if (!formData.message) newErrors.message = t('errors.message_required');
        if (!formData.is_policy) newErrors.is_policy = t('errors.policy_required');
        return newErrors;
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            const { first_name, last_name, company_name, email, tell_no, message } = formData;
            const name = last_name + ' ' + first_name;
            const ccEmails = 'yuki_muto@reband.co.jp'
            const mailtoLink = `mailto:yoshinori_hidaka@reband.co.jp?subject=お問い合わせ&body=名前: ${name}%0A会社名・学校名: ${company_name}%0Aメールアドレス: ${email}%0A電話番号: ${tell_no}%0Aお問い合わせ内容: ${message} &cc=${ccEmails}`;
            window.location.href = mailtoLink;
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box>
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex'}}>
                    <span>{t('Contact Form Label')}</span>
                </Box>
                <Box sx={{ mt: isMobile? 0 : 5, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Name Label')}</h1>
                    <Box sx={{gap: 5, display: 'flex' }}>
                        <TextField 
                            fullWidth 
                            type="text" 
                            name="last_name" 
                            value={formData.last_name} 
                            onChange={handleChange}
                            placeholder={t('Last Name')}
                            variant="outlined"
                            margin="normal"
                            sx={{width: isMobile? '120px' : '300px'}}
                        />
                        <TextField 
                            fullWidth 
                            type="text" 
                            name="first_name" 
                            value={formData.first_name} 
                            onChange={handleChange} 
                            placeholder={t('First Name')}
                            variant="outlined"
                            margin="normal"
                            sx={{width: isMobile? '120px' : '300px'}}
                        />
                    </Box>
                </Box>
                {errors.last_name && (
                    <Box sx={{ color: 'red', textAlign: 'center', mt: 1, ml: isMobile ? 0 : 20 }}>
                        <Typography variant="body2">{errors.last_name}</Typography>
                    </Box>
                )}
                {errors.first_name && (
                    <Box sx={{ color: 'red', textAlign: 'center', mt: 1, ml: isMobile ? 0 : 20 }}>
                        <Typography variant="body2">{errors.first_name}</Typography>
                    </Box>
                )}
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Company Name Label')}</h1>
                    <TextField 
                        fullWidth 
                        type="text" 
                        name="company_name" 
                        value={formData.company_name} 
                        onChange={handleChange}
                        placeholder={t('Company placeholder')}
                        variant="outlined"
                        margin="normal"
                        sx={{width: isMobile? '280px' : "640px"}}
                    />
                </Box>
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Mail Address')}</h1>
                    <TextField 
                        fullWidth 
                        type="text" 
                        name="email" 
                        value={formData.email} 
                        onChange={handleChange}
                        placeholder="xxx@example.co.jp" 
                        variant="outlined"
                        margin="normal"
                        sx={{width: isMobile? '280px' : "640px"}}
                    />
                </Box>
                {errors.email && (
                    <Box sx={{ color: 'red', textAlign: 'center', mt: 1, ml: isMobile ? 0 : 20 }}>
                        <Typography variant="body2">{errors.email}</Typography>
                    </Box>
                )}
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Telephone')}</h1>
                    <TextField 
                        fullWidth 
                        type="text" 
                        name="tell_no" 
                        value={formData.tell_no} 
                        onChange={handleChange}
                        placeholder="000-0000-0000" 
                        variant="outlined"
                        margin="normal"
                        sx={{width: isMobile? '280px' : "640px"}}
                    />
                </Box>
                {errors.tell_no && (
                    <Box sx={{ color: 'red', textAlign: 'center', mt: 1, ml: isMobile ? 0 : 20 }}>
                        <Typography variant="body2">{errors.tell_no}</Typography>
                    </Box>
                )}
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Contact')}</h1>
                    <TextField 
                        fullWidth 
                        name="message" 
                        value={formData.message} 
                        onChange={handleChange}
                        placeholder={t('Contact placeholder')}
                        variant="outlined"
                        margin="normal"
                        multiline
                        sx={{width: isMobile? '280px' : "640px"}}
                        rows={10}
                    />
                </Box>
                {errors.message && (
                    <Box sx={{ color: 'red', textAlign: 'center', mt: 1, ml: isMobile ? 0 : 20 }}>
                        <Typography variant="body2">{errors.message}</Typography>
                    </Box>
                )}
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center', justifyContent: isMobile? '' : 'center', ml: isMobile? 0 : 20 }}>
                    <FormControlLabel
                        control={<Checkbox checked={formData.is_policy} onChange={handleChange} name="is_policy" />}
                        label={<span><Link href="/policy" target="_blank" rel="noopener noreferrer">{t('Personal information Label 1')}</Link>{t('Personal information Label 2')}</span>}
                    />
                </Box>
                {errors.is_policy && (
                    <Box sx={{ color: 'red', textAlign: 'center', mt: 1, ml: isMobile ? 0 : 20 }}>
                        <Typography variant="body2">{errors.is_policy}</Typography>
                    </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: isMobile? '' : 'center', mt: 5, ml: isMobile? 0 : 20 }}>
                    <Button type="submit" variant="contained" 
                        sx={{ 
                            borderRadius: '8px', 
                            backgroundColor: 'white', 
                            width: "200px",
                            color: 'black', 
                            fontSize: '1rem', 
                            padding: '20px 40px',
                            '&:hover': {
                                backgroundColor: '#f0f0f0',
                            }
                        }}>
                        {t('Send Button')}
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 9 }}/>
            </Box>
        </form>
    );
};

export default ContactForm;