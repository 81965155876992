// languageSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialLanguage = 'ja';

const languageSlice = createSlice({
  name: 'language',
  initialState: initialLanguage,
  reducers: {
    setLanguage: (state, action) => action.payload,
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
