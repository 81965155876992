import { useEffect } from 'react';
import { Box, Divider, useMediaQuery } from '@mui/material';
import Header from '../components/header';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useLanguage from '../useLanguage';

const NewsPage = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const newsItems = [
        // {
        //     date: "2024.08.01",
        //     text: t('News 2')
        // },
        {
            date: "2024.06.06",
            text: t('News 1')
        }
    ]

    const { language }= useLanguage();

    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);


    return (
        <div
            style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
            }}
        >
            <Header textColor="#000000" backgroundColor="white"/>
            <Box sx={{ flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                mt: 12,
                ml: isMobile? 3 : 8,
                pt: '64px'
            }}>
                <h1 style={{ fontSize: isMobile? '2rem' : '4rem', marginBottom: '0.5rem' }}>News.</h1>
                {i18n.language === 'ja' && (
                    <h3 style={{ marginTop: '0' }}>ニュース</h3>
                )}
                {isMobile && 
                    <Divider sx={{ mt: 3, mb: 3, mr: 2 }} />
                }
                {newsItems.map((item) => (
                    <>
                        <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                            <span style={{ fontSize: '1rem', width: '15rem' }}>{item.date}</span>
                            {isMobile ? (
                                    <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                                        <span style={{ fontSize: '1rem' }}>{item.text}</span>
                                    </Box>
                                ) 
                                :
                                (
                                    <span style={{ fontSize: '1rem' }}>{item.text}</span>
                                )
                            }
                        </Box>
                        <Divider sx={{ mt: 3, mb: 3, mr: isMobile? 2 : 20 }} />
                    </>
                ))}
            </Box>
        </div>

    )
}

export default NewsPage;