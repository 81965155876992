import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './store';
import i18n from './i18n';
import { setLanguage } from './languageSlice';

const useLanguage = () => {
  const dispatch = useDispatch();
  const language = useSelector((state: RootState) => state.language);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const changeLanguage = (locale: string) => {
    dispatch(setLanguage(locale));
  };

  return { language, changeLanguage };
};

export default useLanguage;
