import React, {useEffect} from 'react';
import { Box, Divider, useMediaQuery } from '@mui/material';
import Header from '../components/header';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useLanguage from '../useLanguage';

const CareersPage = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const formatText = (text: string) => {
        return text.split('\n').map((str, index) => (
            <React.Fragment key={index}>
                {str}
                <br />
            </React.Fragment>
        ));
    };
    const { language }= useLanguage();

    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);

    return (
        <div
            style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
            }}
        >
            <Header textColor="#000000" backgroundColor="white"/>
            <Box sx={{ flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                mt: 12,
                ml: isMobile? 3 : 8,
                pt: '64px'
            }}>
                <h1 style={{ fontSize: isMobile? '2rem' : '4rem', marginBottom: '0.5rem' }}>Careers.</h1>
                {i18n.language === 'ja' && (
                    <h3 style={{ marginTop: '0' }}>採用情報</h3>
                )}
                {isMobile && 
                    <Divider sx={{ mt: 3, mb: 3, mr: 2 }} />
                }
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: "15px"}}>
                        {formatText(t('Careers apology Sales'))}
                    </span>
                </Box>
                <Box sx={{ mt: isMobile? 0 : 3, ml: isMobile ? 1 : 4, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: "15px"}}>
                        {formatText(t('Careers apology Sales Detail'))}
                    </span>
                </Box>
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: "15px"}}>
                        {formatText(t('Careers apology Technical'))}
                    </span>
                </Box>
                <Box sx={{ mt: isMobile? 0 : 3, ml: isMobile ? 1 : 4, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: "15px"}}>
                        {formatText(t('Careers apology Technical Detail'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 9 }}/>
            </Box>
        </div>

    )
}

export default CareersPage;